import { MenuEntry } from '@pancakeswap-libs/uikit'

const CACHE_KEY = 'pancakeSwapLanguage'

const storedLangCode = localStorage.getItem(CACHE_KEY)


const config: MenuEntry[] = [
]

const enHome=  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://chilliswap.io/',
    calloutClass: 'menulink'
  }

const entrade = {
  label: 'Trade',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'Exchange',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'Liquidity',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const enFarms=  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const enPools={
  label: 'Pools',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const enMore={
  label: 'More',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'Blog',
      href: '#',
    },
  ],
}

// fas lang

const fasHome=  {
    label: 'خانه',
    icon: 'HomeIcon',
    href: 'https://chilliswap.io/',
    calloutClass: 'menulink'
  }

const fastrade = {
  label: 'تجارت',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'تبادل',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'نقدینگی',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const fasFarms=  {
    label: 'مزارع',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const fasPools={
  label: 'استخرها',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const fasMore={
  label: 'بیشتر',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'وبلاگ',
      href: '#',
    },
  ],
}

// zh cn language


const zhcnHome=  {
    label: '家',
    icon: 'HomeIcon',
    href: 'https://chilliswap.io/',
    calloutClass: 'menulink'
  }

const zhcntrade = {
  label: '贸易',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: '交换',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: '流动性',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const zhcnFarms=  {
    label: '农场',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const zhcnPools={
  label: '泳池',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const zhcnMore={
  label: '更多的',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: '博客',
      href: '#',
    },
  ],
}



// zh TW language

const zhtwHome=  {
    label: '家',
    icon: 'HomeIcon',
    href: 'https://chilliswap.io/',
    calloutClass: 'menulink'
  }

const zhtwtrade = {
  label: '貿易',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: '交換',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: '流動性',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const zhtwFarms=  {
    label: '農場',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const zhtwPools={
  label: '泳池',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const zhtwMore={
  label: '更多的',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: '博客',
      href: '#',
    },
  ],
}

// ar language

const arHome=  {
  label: 'منزل، بيت',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const artrade = {
label: 'تجارة',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'تبادل',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'السيولة',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const arFarms=  {
  label: 'مزارع',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const arPools={
label: 'حمامات السباحة',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const arMore={
label: 'أكثر',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'مدونة او مذكرة',
    href: '#',
  },
],
}


// ca language

const caHome=  {
  label: 'Inici',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const catrade = {
label: 'Comerç',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Intercanvi',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Liquidity',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const caFarms=  {
  label: 'Granges',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const caPools={
label: 'Piscines',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const caMore={
label: 'Més',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Bloc',
    href: '#',
  },
],
}

// cs language
const csHome=  {
  label: 'Domov',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const cstrade = {
label: 'Obchod',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Výměna',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Likvidita',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const csFarms=  {
  label: 'Farmy',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const csPools={
label: 'Bazény',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const csMore={
label: 'Více',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}



// da language

const daHome=  {
  label: 'Hjem',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const datrade = {
label: 'Handle',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Udveksling',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Likviditet',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const daFarms=  {
  label: 'Gårde',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const daPools={
label: 'Puljer',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const daMore={
label: 'Mere',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// de language
const deHome=  {
  label: 'Zuhause',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const detrade = {
label: 'Handel',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Austausch',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Liquidität',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const deFarms=  {
  label: 'Bauernhöfe',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const dePools={
label: 'Pools',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const deMore={
label: 'Mehr',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// nl language

const nlHome=  {
  label: 'Huis',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const nltrade = {
label: 'Handel',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Uitwisseling',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Liquiditeit',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const nlFarms=  {
  label: 'Boerderijen',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const nlPools={
label: 'Zwembaden',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const nlMore={
label: 'Meer',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// fil language

const filHome=  {
  label: 'Bahay',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const filtrade = {
label: 'Kalakal',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Palitan',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Pagkatubig',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const filFarms=  {
  label: 'Mga bukid',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const filPools={
label: 'Mga pool',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const filMore={
label: 'Dagdag pa',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}


// fr language
const frHome=  {
  label: 'Domicile',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}


const frtrade = {
  label: 'Échanger',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'Échange',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'Liquidité',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const frFarms=  {
    label: 'Fermes',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const frPools={
  label: 'Piscines',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const frMore={
  label: 'Suite',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'Blog',
      href: '#',
    },
  ],
}

// el language
const elHome=  {
  label: 'Σπίτι',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}
const eltrade = {
  label: 'Εμπορικές συναλλαγές',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'Ανταλλαγή',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'Ρευστότητα',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const elFarms=  {
    label: 'Φάρμες',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const elPools={
  label: 'Πισίνες',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const elMore={
  label: 'Περισσότερο',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'Ιστολόγιο',
      href: '#',
    },
  ],
}

// hi language
const hiHome=  {
  label: 'घर',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}
const hitrade = {
  label: 'व्यापार',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'अदला बदली',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'लिक्विडिटी',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const hiFarms=  {
    label: 'फार्म',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const hiPools={
  label: 'ताल',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const hiMore={
  label: 'अधिक',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'ब्लॉग',
      href: '#',
    },
  ],
}

// hu language

const huHome=  {
  label: 'itthon',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}
const hutrade = {
  label: 'Kereskedelmi',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'Csere',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'Likviditás',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const huFarms=  {
    label: 'Farmok',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const huPools={
  label: 'Medencék',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const huMore={
  label: 'Több',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'Blog',
      href: '#',
    },
  ],
}

// id language
const idHome=  {
  label: 'Rumah',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}
const idtrade = {
  label: 'Perdagangan',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'Bertukar',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'Likuiditas',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const idFarms=  {
    label: 'Peternakan',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const idPools={
  label: 'Pools',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const idMore={
  label: 'Lebih',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'Blog',
      href: '#',
    },
  ],
}


// it language
const itHome=  {
  label: 'Casa',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}
const ittrade = {
  label: 'Commercio',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'Scambio',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: 'Liquidità',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const itFarms=  {
    label: 'Fattorie',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const itPools={
  label: 'Piscine',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const itMore={
  label: 'Di più',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'Blog',
      href: '#',
    },
  ],
}

// ja language
const jaHome=  {
  label: 'ホームホーム',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}
const jatrade = {
  label: 'トレード',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: '両替',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: '流動性',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const jaFarms=  {
    label: '農場',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const jaPools={
  label: 'プール',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const jaMore={
  label: 'もっと',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: 'ブログ',
      href: '#',
    },
  ],
}

// ko language
const koHome=  {
  label: '집',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}
const kotrade = {
  label: '무역',
  icon: 'TradeIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: '교환',
      href: 'https://exchange.chilliswap.io/#/swap',
    },
    {
      label: '유동성',
      href: 'https://exchange.chilliswap.io/#/pool',
    },
  ],
}

const koFarms=  {
    label: '전원',
    icon: 'FarmIcon',
    href: 'https://chilliswap.io/farms',
    calloutClass: 'menulink'
  }

const koPools={
  label: '풀',
  icon: 'PoolIcon',
  href: 'https://chilliswap.io/pools',
  calloutClass: 'menulink'
}

const koMore={
  label: '더',
  icon: 'MoreIcon',
  calloutClass: 'menulink',
  items: [
    {
      label: 'List Token',
      href: 'https://request.chilliswap.io/',
    },
    {
      label: '블로그',
      href: '#',
    },
  ],
}


// pt -BR language

const ptbrHome=  {
  label: 'Casa',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const ptbrtrade = {
label: 'Troca',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Intercâmbio',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Liquidez',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const ptbrFarms=  {
  label: 'Fazendas',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const ptbrPools={
label: 'Piscinas',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const ptbrMore={
label: 'Mais',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// ro language
const roHome=  {
  label: 'Acasă',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const rotrade = {
label: 'Comerț',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'schimb valutar',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Lichiditate',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const roFarms=  {
  label: 'Ferme',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const roPools={
label: 'Bazine',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const roMore={
label: 'Mai mult',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// ru language
const ruHome=  {
  label: 'Дом',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const rutrade = {
label: 'Торговля',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Обмен',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Ликвидность',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const ruFarms=  {
  label: 'Фермы',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const ruPools={
label: 'Бассейны',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const ruMore={
label: 'Более',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Блог',
    href: '#',
  },
],
}

// sr language
const srHome=  {
  label: 'Кућа',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const srtrade = {
label: 'Трговина',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Размена',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Ликвидност',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const srFarms=  {
  label: 'Фарме',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const srPools={
label: 'Базени',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const srMore={
label: 'Више',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Блог',
    href: '#',
  },
],
}

// es-Es language
const esesHome=  {
  label: 'Casa',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const esestrade = {
label: 'Comercio',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Intercambio',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Liquidez',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const esesFarms=  {
  label: 'Granjas',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const esesPools={
label: 'Quinielas',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const esesMore={
label: 'Más',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// sv-SE language
const svseHome=  {
  label: 'Hem',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const svsetrade = {
label: 'Handel',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Utbyta',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Likviditet',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const svseFarms=  {
  label: 'Gårdar',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const svsePools={
label: 'Pooler',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const svseMore={
label: 'Mer',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// ta language
const taHome=  {
  label: 'வீடு',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const tatrade = {
label: 'வர்த்தகம்',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'பரிமாற்றம்',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'நீர்மை நிறை',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const taFarms=  {
  label: 'பண்ணைகள்',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const taPools={
label: 'குளங்கள்',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const taMore={
label: 'மேலும்',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'வலைப்பதிவு',
    href: '#',
  },
],
}

// tr language
const trHome=  {
  label: 'Ev',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const trtrade = {
label: 'Ticaret',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Değiş tokuş',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Likidite',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const trFarms=  {
  label: 'Çiftlikler',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const trPools={
label: 'Havuzlar',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const trMore={
label: 'Daha',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

// uk language
const ukHome=  {
  label: 'Додому',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const uktrade = {
label: 'Торгівля',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Обмін',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Ліквідність',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const ukFarms=  {
  label: 'Ферми',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const ukPools={
label: 'Басейни',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const ukMore={
label: 'Більше',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Блог',
    href: '#',
  },
],
}

// vi language
const viHome=  {
  label: 'Trang Chủ',
  icon: 'HomeIcon',
  href: 'https://chilliswap.io/',
  calloutClass: 'menulink'
}

const vitrade = {
label: 'Buôn bán',
icon: 'TradeIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'Đổi',
    href: 'https://exchange.chilliswap.io/#/swap',
  },
  {
    label: 'Tính thanh khoản',
    href: 'https://exchange.chilliswap.io/#/pool',
  },
],
}

const viFarms=  {
  label: 'Trang trại',
  icon: 'FarmIcon',
  href: 'https://chilliswap.io/farms',
  calloutClass: 'menulink'
}

const viPools={
label: 'Hồ bơi',
icon: 'PoolIcon',
href: 'https://chilliswap.io/pools',
calloutClass: 'menulink'
}

const viMore={
label: 'Hơn',
icon: 'MoreIcon',
calloutClass: 'menulink',
items: [
  {
    label: 'List Token',
    href: 'https://request.chilliswap.io/',
  },
  {
    label: 'Blog',
    href: '#',
  },
],
}

 if(storedLangCode==="fas"){
  config.push(fasHome)
  config.push(fastrade)
  config.push(fasFarms)
  config.push(fasPools)
  config.push(fasMore)
}else if(storedLangCode==="zh-CN"){
  config.push(zhcnHome)
  config.push(zhcntrade)
  config.push(zhcnFarms)
  config.push(zhcnPools)
  config.push(zhcnMore)
}else if(storedLangCode === "zh-TW"){
  config.push(zhtwHome)
  config.push(zhtwtrade)
  config.push(zhtwFarms)
  config.push(zhtwPools)
  config.push(zhtwMore)
}else if(storedLangCode === "ar"){
  config.push(arHome)
  config.push(artrade)
  config.push(arFarms)
  config.push(arPools)
  config.push(arMore)
}else if(storedLangCode === "ca"){
  config.push(caHome)
  config.push(catrade)
  config.push(caFarms)
  config.push(caPools)
  config.push(caMore)
}else if(storedLangCode === "cs"){
  config.push(csHome)
  config.push(cstrade)
  config.push(csFarms)
  config.push(csPools)
  config.push(csMore)
}else if(storedLangCode === "da"){
  config.push(daHome)
  config.push(datrade)
  config.push(daFarms)
  config.push(daPools)
  config.push(daMore)
}else if(storedLangCode === "de"){
  config.push(deHome)
  config.push(detrade)
  config.push(deFarms)
  config.push(dePools)
  config.push(deMore)
}else if(storedLangCode === "nl"){
  config.push(nlHome)
  config.push(nltrade)
  config.push(nlFarms)
  config.push(nlPools)
  config.push(nlMore)
}else if(storedLangCode === "fil"){
  config.push(filHome)
  config.push(filtrade)
  config.push(filFarms)
  config.push(filPools)
  config.push(filMore)
}else if(storedLangCode === "fr"){
  config.push(frHome)
  config.push(frtrade)
  config.push(frFarms)
  config.push(frPools)
  config.push(frMore)
}else if(storedLangCode === "el"){
  config.push(elHome)
  config.push(eltrade)
  config.push(elFarms)
  config.push(elPools)
  config.push(elMore)
}else if(storedLangCode === "hi"){
  config.push(hiHome)
  config.push(hitrade)
  config.push(hiFarms)
  config.push(hiPools)
  config.push(hiMore)
}else if(storedLangCode === "hu"){
  config.push(huHome)
  config.push(hitrade)
  config.push(huFarms)
  config.push(huPools)
  config.push(hiMore)
}else if(storedLangCode === "id"){
  config.push(idHome)
  config.push(idtrade)
  config.push(idFarms)
  config.push(idPools)
  config.push(idMore)
}else if(storedLangCode === "it"){
  config.push(itHome)
  config.push(ittrade)
  config.push(itFarms)
  config.push(itPools)
  config.push(itMore)
}else if(storedLangCode === "ja"){
  config.push(jaHome)
  config.push(jatrade)
  config.push(jaFarms)
  config.push(jaPools)
  config.push(jaMore)
}else if(storedLangCode === "ko"){
  config.push(koHome)
  config.push(kotrade)
  config.push(koFarms)
  config.push(koPools)
  config.push(koMore)
}else if(storedLangCode === "pt -BR"){
  config.push(ptbrHome)
  config.push(ptbrtrade)
  config.push(ptbrFarms)
  config.push(ptbrPools)
  config.push(ptbrMore)
}else if(storedLangCode === "ro"){
  config.push(roHome)
  config.push(rotrade)
  config.push(roFarms)
  config.push(roPools)
  config.push(roMore)
}else if(storedLangCode === "ru"){
  config.push(ruHome)
  config.push(rutrade)
  config.push(ruFarms)
  config.push(ruPools)
  config.push(ruMore)
}else if(storedLangCode === "sr"){
  config.push(srHome)
  config.push(srtrade)
  config.push(srFarms)
  config.push(srPools)
  config.push(srMore)
}else if(storedLangCode === "es-ES"){
  config.push(esesHome)
  config.push(esestrade)
  config.push(esesFarms)
  config.push(esesPools)
  config.push(esesMore)
}else if(storedLangCode === "sv-SE"){
  config.push(svseHome)
  config.push(svsetrade)
  config.push(svseFarms)
  config.push(svsePools)
  config.push(svseMore)
}else if(storedLangCode === "ta"){
  config.push(taHome)
  config.push(tatrade)
  config.push(taFarms)
  config.push(taPools)
  config.push(taMore)
}else if(storedLangCode === "tr"){
  config.push(trHome)
  config.push(trtrade)
  config.push(trFarms)
  config.push(trPools)
  config.push(trMore)
}else if(storedLangCode === "uk"){
  config.push(ukHome)
  config.push(uktrade)
  config.push(ukFarms)
  config.push(ukPools)
  config.push(ukMore)
}else if(storedLangCode === "vi"){
  config.push(viHome)
  config.push(vitrade)
  config.push(viFarms)
  config.push(viPools)
  config.push(viMore)
}else{
  config.push(enHome)
  config.push(entrade)
  config.push(enFarms)
  config.push(enPools)
  config.push(enMore)
}

export default config
