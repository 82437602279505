import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'CHILLI-BUSD LP',
    lpAddresses: {
      97: '0x2E63A4098C307311A463CAF4a08B3ea947321394',
      56: '0xd94E43E8D123dAaa35428E67FF1B11F6a8D22028',
    },
    tokenSymbol: 'CHILLI',
    tokenAddresses: {
      97: '0xf88ff3B6D22be2a28227968d9fbc6346ba6A2d61',
      56: '0x857f5078cF1E05e5753dE2cC43e142040AAB8471',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'CHILLI-BNB LP',
    lpAddresses: {
      97: '0x3E8C8b7229Cf5a01B9CA2Ee55828E1f1EFCdB637',
      56: '0xBBE95a0C32e76B54bfF1F0EF8F2D33Db6C4A5cDf',
    },
    tokenSymbol: 'CHILLI',
    tokenAddresses: {
      97: '0xf88ff3B6D22be2a28227968d9fbc6346ba6A2d61',
      56: '0x857f5078cF1E05e5753dE2cC43e142040AAB8471',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'CHILLI',
    lpAddresses: {
      97: '0xf88ff3B6D22be2a28227968d9fbc6346ba6A2d61',
      56: '0xd94E43E8D123dAaa35428E67FF1B11F6a8D22028', // CAKE-BUSD LP
    },
    tokenSymbol: 'CHILLI',
    tokenAddresses: {
      97: '0xf88ff3B6D22be2a28227968d9fbc6346ba6A2d61',
      56: '0x857f5078cF1E05e5753dE2cC43e142040AAB8471',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
