export default {
  cake: {
    56: '0x857f5078cF1E05e5753dE2cC43e142040AAB8471',
    97: '0xf88ff3B6D22be2a28227968d9fbc6346ba6A2d61',
  },
  masterChef: {
    56: '0xC62d6eFC52F4E2b9cF8A1AC70BCB613DBc79B6C3',
    97: '0x078758F853F30DE00edB16E2feEF48FD5D0a1Cab',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xBaE36c04b80FE01bb1611160B199fACB7E3CdC27',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F',
  },
}
